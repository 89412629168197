import React from 'react';
import PropTypes from 'prop-types';
import Header from '../components/header/header';

const Layout = ({ children }) => {

  return (
    <>
      <Header  />
      <div
        style={{
          margin: '0 auto',
          padding: '0 1.0875rem 1.45rem'
        }}
      >
        <main>{children}</main>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  bannerImage: PropTypes.string,
  paragraph: PropTypes.string,
  title: PropTypes.string
};

export default Layout;
